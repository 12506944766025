<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        An acidic solution of potassium permanganate (
        <chemical-latex content="KMnO4" />
        ) was reacted with a solution of iron(II) chloride (
        <chemical-latex content="FeCl2" />
        ). The net balanced ionic equation for the reaction is:
      </p>
      <p class="mb-2">
        <chemical-latex content="MnO^{4-} + 5Fe^{2+} + 8H^+ -> Mn^{2+} + 5Fe^{3+} + 4H2O" />
      </p>

      <p class="mb-2">
        (a) Calculate the molarity of the iron(II) chloride if it's normality is
        <stemble-latex content="$0.1\,\text{M}$" />.
      </p>
      <calculation-input
        class="mb-5"
        v-model="inputs.input1"
        prepend-text="$\text{M}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (b) Calculate the normality of the
        <chemical-latex content="KMnO4" />
        if its molarity is
        <stemble-latex content="$0.1\,\text{M}$" />.
      </p>
      <calculation-input
        v-model="inputs.input2"
        prepend-text="$\text{N}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A4Q3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
};
</script>
